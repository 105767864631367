import FormatTimes from 'modules/formatTimes';
import CheckCoupon from 'modules/checkCoupon';
import Payment from 'modules/payment';
import CountDown from 'modules/countDown';

import {LandingPageLoad, landingDescriptionbox} from "./modules/landingPage"

export {FormatTimes};

// Load and associate jQuery plugins to our global jQuery variable.
// require('jsrender')($);
// require('jquery.cookie');
// !!$.cookie;
import './modules/jquery-setup';

var googleLoginURL = function () {
    var google_login_button_div = $('#g_id_onload');
    if (google_login_button_div.length) {
        let utmParams = extractUtmParams(true);
        // data-login_uri="/api/auth/google-login?utm_source=google_login&utm_campaign=login">
        let login_url = `/api/auth/google-login?${utmParams}`
        google_login_button_div.attr('data-login_uri', login_url); // Set your data attribute using jQuery
    }
}

googleLoginURL();

// Custom date formatter for jsRender
$.views.converters({
    shortDate: function (value) {
        return moment(value).format('MMM Do YYYY');
    }
});

function extractUtmParams(asString = false) {
    const pageParams = new URLSearchParams(window.location.search);
    let utmParams = {};

    function addUtmParam(params, paramName) {
        if (params.has(paramName)) {
            utmParams[paramName] = params.get(paramName);
        }
    }

    // Define which UTM parameters to extract
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'campaign_id', 'adgroup_id', 'keyword', 'device', 'gclid'];
    utmKeys.forEach((key) => addUtmParam(pageParams, key));

    if (asString) {
        // Convert utmParams object to a query string
        const paramsString = Object.keys(utmParams)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(utmParams[key]))
            .join('&');
        return paramsString;
    }

    return utmParams;
}

var signup = function () {
    var $signup = $('#signup');
    var $spinner = $('#spinner');
    var $submitbutton = $('#signup-link');

    $signup.on('submit', function (event) {
        event.preventDefault();
        $spinner.removeClass('d-none');
        $submitbutton.addClass('disabled');
        var url = $signup.attr('action');
        var redirect = $('#signup-link').data('next');
        var $instruction = $('#choices-button');
        var $keywords = $('#choices-tags');
        if ($instruction.length) {

            redirect += '?instruction=' + encodeURIComponent($instruction[0].value)
            redirect += '&keywords=' + encodeURIComponent($keywords[0].value)
            redirect += '&description=' + encodeURIComponent(landingDescriptionbox.getText())

        }

        var $email = $('#email');
        var $username = $('#username');
        var $companyname = $('#companyname');
        var $password = $('#password');
        var $error = $('#form_error');

        // Use the extractUtmParams function to get UTM parameters
        let utmParams = extractUtmParams();


        var data = {
            'email': $email.val(),
            'username': $username.val(),
            'companyname': $companyname.val(),
            'password': $password.val(),

        }

        return $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
            processData: false,
            beforeSend: function (xhr) {

                xhr.setRequestHeader('X-UTM', JSON.stringify(utmParams));
            },
            success: function (response, status, xhr) {
                //facebook tracking
                // if (window.location.hostname.includes("ngrok") === false) {
                //     fbq('track', 'Lead', {});
                //
                // }

                $error.hide();
                window.location.replace(redirect);
            },
            error: function (response, status, xhr) {
                $spinner.addClass("d-none");
                $submitbutton.removeClass('disabled');
                $error.text(error_response(response));
                $error.show();
            }
        });
    });
};


var login = function () {
    var $login = $('#login');
    var $spinner = $('#spinner');
    var $submitbutton = $('#login-link');

    $login.on('submit', function (event) {
        event.preventDefault();
        $spinner.removeClass('d-none');
        $submitbutton.addClass('disabled');
        var url = $login.attr('action');
        var redirect = $('#login-link').data('next');
        var $email = $('#email');
        var $password = $('#password');
        var $error = $('#form_error');

        var data = {
            'email': $email.val(),
            'password': $password.val()
        }

        return $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
            processData: false,
            success: function (response, status, xhr) {
                window.location.replace(redirect);
            },
            error: function (response, status, xhr) {
                $spinner.addClass("d-none");
                $submitbutton.removeClass('disabled');
                $error.text(error_response(response));
                $error.show();
            }
        });
    });
};

var shopifyLogin = function () {
    var $shopifylogin = $('#shopify-login');
    var $spinner = $('#spinner');
    var $submitbutton = $('#shopify-login-link');

    $shopifylogin.on('submit', function (event) {
        event.preventDefault();
        $spinner.removeClass('d-none');
        $submitbutton.addClass('disabled');
        var url = $shopifylogin.attr('action');
        var redirect = $('#shopify-login-link').data('next');

        var $error = $('#form_error');

        if ($('#password').val()) {
            var $password = $('#password');
            var $user_email = $('#user-email')
            var data = {
                'user_email': $user_email.val(),
                'password': $password.val()
            }
        } else {
            var $login_token = $('#login-token')
            var data = {
                'login_token': $login_token.val()
            }
        }

        return $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
            processData: false,
            success: function (response, status, xhr) {
                window.location.replace(redirect);
            },
            error: function (response, status, xhr) {
                $spinner.addClass("d-none");
                $submitbutton.removeClass('disabled');
                $error.text(error_response(response));
                $error.show();
            }
        });
    });
};

export function error_response(response) {
    if (response.responseJSON != null) {
        if (typeof response.responseJSON.error === 'object') {
            var errcount = Object.keys(response.responseJSON.error).length
            var errortext = []

            for (let i = 0; i < errcount; i++) {
                errortext += (Object.keys(response.responseJSON.error)[i] + ' : ' + Object.values(response.responseJSON.error)[i] + '\n')
            }
        } else {
            errortext = response.responseJSON.error
        }
    } else {

        errortext = 'Wilfie is very busy right now, Please try again later'
    }

    if (errortext == null) {
        errortext = 'Wilfie is very busy right now, Please try again later'
    }
    return errortext
}

var logout = function () {
    var $logoutLink = $('#logout-link');

    $logoutLink.on('click', function (event) {
        event.preventDefault();

        var url = $logoutLink.attr('href');
        var redirect = $logoutLink.data('next');

        return $.ajax({
            type: 'delete',
            url: url,
            dataType: 'json',
            contentType: 'application/json',
            processData: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
            },
            success: function (response, status, xhr) {
                window.location.replace(redirect);
            }
        });
    });
}


function showInfoBox(info_box_data) {
    var infoBox = document.getElementById('info-box')

    if (infoBox) {
        var infoBoxMessage = document.getElementById('info-box-message')


        if (info_box_data && Array.isArray(info_box_data) && info_box_data.length > 0) {

            var info_message = info_box_data.pop()


            var name = info_message.name
            var location = info_message.location
            var subscription = info_message.subscription

            // Set the text of the info box with the name and location
            infoBoxMessage.innerText = name + " from " + location + '\nsigned up to the ' + subscription + ' plan';


            // Set the position of the info box to the bottom left corner

            // Show the info box


            infoBox.style.left = 0;


            // Hide the info box after 5-7 seconds
            setTimeout(function () {
                // infoBox.style.display = "none";
                infoBox.style.left = "-300px";

                // Show the info box again after 1-6 seconds
                setTimeout(function () {
                    showInfoBox(info_box_data);
                }, Math.floor(Math.random() * 12000) + 7000);
            }, Math.floor(Math.random() * 2000) + 3000);
        }
    }
}


var infoboxLoad = function () {
    var info_box_pages = ['try-for-free', '/subscription/pricing', '/subscription/update'];

    if (info_box_pages.some(page => window.location.href.indexOf(page) > -1)) {
        if (typeof info_box_data !== 'undefined') {
            setTimeout(function () {
                // show the info box after 5 seconds
                showInfoBox(info_box_data);
            }, 5000);
        }
    }
}

var countdownTimerLoad = function () {
    var timer_pages = ['/subscription/pricing', 'subscription/create'];

    if (timer_pages.some(page => window.location.href.indexOf(page) > -1)) {
        var span = document.getElementById('time');
        if (span) {
            var countdown = new CountDown(span, 300);
            countdown.start();
            var time = document.getElementById('time')
            time.style.opacity = 1;
        }
    }
}
var countryMetaToCookie = function () {
    let country_code = $('meta[name=country_code]').attr('content');
    if (country_code != '' && country_code != 'None') {
        document.cookie = "country_code=" + country_code + ";expires=" + (new Date(Date.now() + 31556926000)).toUTCString() + ";path=/"
    }
}

var featureMetaToCookie = function () {
    let feature_page = $('meta[name=feature_page]').attr('content');

    if (feature_page && feature_page != '' && feature_page != 'None') {
        document.cookie = "feature_page=" + feature_page + ";expires=" + (new Date(Date.now() + 31556926000)).toUTCString() + ";path=/"
    }
}


// Initialize everything when the DOM is ready.
$(document).ready(function () {

    let csrfToken = $.cookie('csrf_access_token')


    logout();
    signup();
    infoboxLoad();
    countdownTimerLoad();
    countryMetaToCookie();
    featureMetaToCookie();


    // console.log(window.location.pathname)
    switch (window.location.pathname) {
        case '/login':
            login();
            break;
        case '/shopify/welcome':
            shopifyLogin();
            break;
        case '/shopify/':
            shopifyLogin();
            break;
        case '/copywriting/try-for-free':
        case '/copywriting/try-for-free/uk':
        case '/copywriting/try-it-now':
        case '/copywriting/try-it-now/uk':
            LandingPageLoad()
            break;
        case '/chat/try-for-free':
        case '/chat/try-for-free/uk':
        case '/chat/try-it-now':
        case '/chat/try-it-now/uk':
            LandingPageLoad("Ask me anything")
            break;
        case '/ai/humaniser/try-for-free':
        case '/ai/humaniser/try-for-free/uk':
        case '/ai/humaniser/try-it-now':
        case '/ai/humaniser/try-it-now/uk':
            LandingPageLoad('Enter text to be Humanised')
            break;
        case '/ai/detector/try-for-free':
        case '/ai/detector/try-for-free/uk':
        case '/ai/detector/try-it-now':
        case '/ai/detector/try-it-now/uk':
            LandingPageLoad('Enter text for AI Detection')
            break;
        case '/video/try-for-free':
        case '/video/try-for-free/uk':
        case '/video/try-it-now':
        case '/video/try-it-now/uk':
            LandingPageLoad('Enter topic for video')
            break;
        case '/searchgpt/try-for-free':
        case '/searchgpt/try-for-free/uk':
        case '/searchgpt/try-it-now':
        case '/searchgpt/try-it-now/uk':
            LandingPageLoad('Ask me anything')
            break;
        case '/assistant/try-for-free':
        case '/assistant/try-for-free/uk':
        case '/assistant/try-it-now':
        case '/assistant/try-it-now/uk':
            LandingPageLoad('Ask me anything')
            break;
        case '/text-to-image/try-for-free':
        case '/text-to-image/try-for-free/uk':
        case '/text-to-image/try-it-now':
        case '/text-to-image/try-it-now/uk':
            LandingPageLoad('What image would you like?')
            break;


    }


    new FormatTimes('.js-from-now', '.js-short-date', '.js-short-date-time');
    new CheckCoupon('#coupon_code', csrfToken);
    new Payment('#subscription_form');
    new Payment('#payment_form');

});
